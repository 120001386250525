import {
  CompanyBase,
  ExternalLinkBase,
  ICorporateInvestor,
  ICorporateInvestorBase,
  IErrorsStore,
  InvestInLps,
  InvestmentPurpose,
  InvestmentTargetRegion,
  Kind,
  LeadStatusBase,
  LocationBase,
  MarketBase,
  RoundBase,
} from '@/types'
import { action, observable } from 'mobx'

export default class CorporateInvestor implements ICorporateInvestor {
  @observable about = ''

  @observable fundSize = ''

  @observable id = ''

  @observable investInLps = InvestInLps.UNKNOWN

  @observable investmentPurpose = InvestmentPurpose.UNKNOWN

  @observable investmentTargetMarkets: MarketBase[] = []

  @observable investmentTargetRegion = InvestmentTargetRegion.UNKNOWN

  @observable investmentTargetRounds: RoundBase[] = []

  @observable isListed = false

  @observable kind = Kind.VC

  @observable locations: LocationBase[] = []

  @observable logo = ''

  @observable name = ''

  @observable portfolioCount = 0

  @observable portfolios: CompanyBase[] = []

  @observable slug = ''

  @observable url = ''

  @observable isVerified: boolean

  @observable leadStatus = LeadStatusBase.UNKNOWN

  @observable externalLinks: ExternalLinkBase[] = []

  errorsStore: IErrorsStore

  @action
  _mapBase(base: ICorporateInvestorBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: ICorporateInvestorBase): void {
    this._mapBase(base)
  }

  constructor(base: ICorporateInvestorBase) {
    this._mapBase(base)
  }
}
