import { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import styles from './index.module.scss'

export const TopPageHeroAbout241219: FC = observer(() => {
  const { t } = useTranslation(['index'])

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.title}>
              <p className={styles.sub}>{t("What's TOSHIKA DB")}</p>
              <p className={styles.main}>
                {t('最適な投資家に､')}
                <br />
                {t('最速で出会う｡')}
                <br />
              </p>
            </div>
            <p className={styles.text}>
              {t('スタートアップのための包括的データベース。')}
              <br />
              {t(
                '国内外の多様な投資主体（ベンチャーキャピタル、コーポレートベンチャーキャピタル、上場企業、非上場企業など）を網羅したデータベースです。投資ステージ、対象領域、投資スタイルなど、カスタマイズ可能な条件でフィルタリングすることで、スタートアップが自社に最適な投資パートナーを効率的に見つけ出すことができます。'
              )}
            </p>
          </div>
          <img
            src='/images/common/home/investor_list241219.webp'
            alt='corporate investor list'
            className={styles.right}
          />
        </div>
      </div>
    </section>
  )
})
