import CorporateInvestorList from '@/entities/CorporateInvestorList'
import symbols from '@/symbols'
import {
  ICorporateInvestorListFactory,
  ICorporateInvestorList,
  CorporateInvestorListFactoryInput,
  IErrorsStore,
} from '@/types'
import { inject, injectable } from 'inversify'

@injectable()
export default class CorporateInvestorListFactory implements ICorporateInvestorListFactory {
  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: CorporateInvestorListFactoryInput): ICorporateInvestorList {
    const corporateInvestorList = new CorporateInvestorList(input.base)

    corporateInvestorList.errorsStore = this.errorsStore

    return corporateInvestorList
  }
}
