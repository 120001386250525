import { FC } from 'react'
import { observer } from 'mobx-react'
import { TopPageHeroTopEn } from '@/components/organisms/TopPageHeroTopEn'
import { TopPageHeroFeatureList241219 } from '@/components/organisms/TopPageHeroFeatureList241219'
import { TopPageHeroCTA241219 } from '@/components/organisms/TopPageHeroCTA241219'
import { useRouter } from 'next/router'
import { TopPageHeroTopJa241219 } from '@/components/organisms/TopPageHeroTopJa241219'
import styles from './index.module.scss'
import { TopPageHeroAbout241219 } from '../TopPageHeroAbout241219'
import { TopPageHeroFAQ241219 } from '../TopPageHeroFAQ241219'

export const TopPageHero241219: FC = observer(() => {
  const router = useRouter()
  const { locale } = router
  return (
    <div className={styles.container}>
      {locale === 'ja' ? <TopPageHeroTopJa241219 /> : <TopPageHeroTopEn />}
      <TopPageHeroAbout241219 />
      <TopPageHeroFeatureList241219 />
      <TopPageHeroFAQ241219 />
      <TopPageHeroCTA241219 />
    </div>
  )
})
