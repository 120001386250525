import { action, observable } from 'mobx'
import { ICorporateInvestorList, ICorporateInvestorListBase, IErrorsStore } from '@/types'

export default class CorporateInvestorList implements ICorporateInvestorList {
  @observable id = ''

  @observable interviewStatus = null

  @observable mailStatus = null

  @observable isListed = false

  @observable notes = ''

  @observable corporateInvestor = null

  errorsStore: IErrorsStore

  @action
  _mapFromBase(base: ICorporateInvestorListBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: ICorporateInvestorListBase): void {
    this._mapFromBase(base)
  }

  constructor(base: ICorporateInvestorListBase) {
    this._mapFromBase(base)
  }
}
