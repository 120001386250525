import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchCorporateInvestorStoreInput,
  FetchCorporateInvestorUseCaseOutput,
  FetchCorporateInvestorsStoreInput,
  FetchCorporateInvestorsUseCaseOutput,
  FetchExternalLinksStoreInput,
  FetchExternalLinksUseCaseOutput,
  FetchLocationsStoreInput,
  ICommentBase,
  ICorporateInvestor,
  ICorporateInvestorsStore,
  IExternalLinkBase,
  IFetchCorporateInvestorUseCase,
  IFetchCorporateInvestorsUseCase,
  IFetchExternalLinksUseCase,
  IFetchLocationsUseCase,
  IFetchMarketsUseCase,
  IFetchRoundsUseCase,
  ILocationBase,
  IMarketBase,
  IRoundBase,
} from '@/types'

const remoteDevConfig: RemoteDevConfig = {
  name: 'CorporateInvestorsStore',
  global: true,
  remote: false,
}

const CORPORATE_INVESTORS_KEY = 'corporateInvestors'
const EXTERNAL_LINKS_KEY = 'externalLinks'

@remotedev(remoteDevConfig)
@injectable()
export default class CorporateInvestorsStore implements ICorporateInvestorsStore {
  @observable corporateInvestors: ICorporateInvestor[] = []

  @observable searchResults: ICorporateInvestor[] = []

  @observable searchWord = ''

  @observable markets: IMarketBase[] = []

  @observable rounds: IRoundBase[] = []

  @observable locations: ILocationBase[] = []

  @observable corporateInvestorLists: ICorporateInvestor[] = []

  @observable comments: ICommentBase[] = []

  @observable externalLinks: IExternalLinkBase[] = []

  @observable totalCount: Record<string, number> = {}

  @observable totalPages: Record<string, number> = {}

  @observable currentPage: Record<string, number> = {}

  @observable hasNextPage: Record<string, boolean> = {}

  @observable numberOfAllCorporateInvestors: number

  @observable fetchCounter = 0

  constructor(
    @inject(symbols.IFetchCorporateInvestorsUseCase)
    private fetchCorporateInvestorsUseCase: IFetchCorporateInvestorsUseCase,
    @inject(symbols.IFetchCorporateInvestorUseCase)
    private fetchCorporateInvestorUseCase: IFetchCorporateInvestorUseCase,
    @inject(symbols.IFetchMarketsUseCase) private fetchMarketsUseCase: IFetchMarketsUseCase,
    @inject(symbols.IFetchRoundsUseCase) private fetchRoundsUseCase: IFetchRoundsUseCase,
    @inject(symbols.IFetchLocationsUseCase) private fetchLocationsUseCase: IFetchLocationsUseCase,
    @inject(symbols.IFetchExternalLinksUseCase) private fetchExternalLinksUseCase: IFetchExternalLinksUseCase
  ) {
    //
  }

  @action
  _updatecorporateInvestors(corporateInvestors: ICorporateInvestor[]): void {
    this.corporateInvestors = corporateInvestors
  }

  @action
  _updateTotalCount(key: string, totalCount: number): void {
    this.totalCount[key] = totalCount
  }

  @action
  _updateTotalPages(key: string, totalPages: number): void {
    this.totalPages[key] = totalPages
  }

  @action
  _updateCurrentPage(key: string, currentPage: number): void {
    this.currentPage[key] = currentPage
  }

  @action
  updateHasNextPage(key: string, hasNextPage: boolean): void {
    this.hasNextPage[key] = hasNextPage
  }

  @action
  _updateMarkets(markets: IMarketBase[]): void {
    this.markets = markets
  }

  @action
  _updateRounds(markets: IRoundBase[]): void {
    this.rounds = markets
  }

  @action
  _updateLocations(locations: ILocationBase[]): void {
    this.locations = locations
  }

  @action
  _updateExternalLinks(externalLinks: IExternalLinkBase[]): void {
    this.externalLinks = externalLinks
  }

  async fetchCorporateInvestors(
    input: FetchCorporateInvestorsStoreInput
  ): Promise<FetchCorporateInvestorsUseCaseOutput> {
    const output = await this.fetchCorporateInvestorsUseCase.handle(input)

    if (output.isSuccessful) {
      this._updatecorporateInvestors(output.data.corporateInvestors)

      this._updateTotalCount(CORPORATE_INVESTORS_KEY, output.data.totalCount)
      this._updateTotalPages(CORPORATE_INVESTORS_KEY, output.data.totalPages)
      this._updateCurrentPage(CORPORATE_INVESTORS_KEY, output.data.currentPage)
      this.updateHasNextPage(CORPORATE_INVESTORS_KEY, output.data.hasNextPage)

      if (this.fetchCounter === 0) {
        this.numberOfAllCorporateInvestors = output.data.totalCount
      }

      this.fetchCounter++
    } else {
      this._updatecorporateInvestors([])
    }

    return output
  }

  async fetchCorporateInvestor(input: FetchCorporateInvestorStoreInput): Promise<FetchCorporateInvestorUseCaseOutput> {
    const output = await this.fetchCorporateInvestorUseCase.handle(input)

    return output
  }

  async fetchMarkets(): Promise<void> {
    const output = await this.fetchMarketsUseCase.handle()
    const originalMarkets = output.data.markets
    const itMarketIndex = originalMarkets.findIndex((market) => market.slug === 'all')
    if (itMarketIndex) {
      const itMarket = originalMarkets.splice(itMarketIndex, itMarketIndex + 1)

      originalMarkets.unshift(...itMarket)
    }

    this._updateMarkets(originalMarkets)
  }

  async fetchRounds(): Promise<void> {
    const output = await this.fetchRoundsUseCase.handle()

    this._updateRounds(output.data.rounds)
  }

  async fetchLocations(input: FetchLocationsStoreInput): Promise<void> {
    const output = await this.fetchLocationsUseCase.handle(input)

    this._updateLocations(output.data.locations)
  }

  async fetchExternalLinks(input: FetchExternalLinksStoreInput): Promise<FetchExternalLinksUseCaseOutput> {
    const output = await this.fetchExternalLinksUseCase.handle(input)

    if (output.isSuccessful) {
      this._updateExternalLinks(output.data.externalLinks)

      this._updateTotalCount(EXTERNAL_LINKS_KEY, output.data.totalCount)
      this._updateTotalPages(EXTERNAL_LINKS_KEY, output.data.totalPages)
      this._updateCurrentPage(EXTERNAL_LINKS_KEY, output.data.currentPage)
      this.updateHasNextPage(EXTERNAL_LINKS_KEY, output.data.hasNextPage)
    } else {
      this._updateExternalLinks([])
    }

    return output
  }
}
