import { FC, useState } from 'react'
import Link from 'next/link'
import { OutSideOverlay } from '@components/atoms/OutSideOverlay'
import classNames from 'classnames'
import { getUserAvatar, useStores } from '@/utils'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { Avatar2 } from '@components/atoms/Avatar2'
import { GlobalMobileHeaderAccountMenuDropdown } from '@components/organisms/GlobalMobileHeaderAccountMenuDropdown'
import styles from './index.module.scss'

export const GlobalMobileHeaderAccountMenu: FC = observer(() => {
  const { t } = useTranslation(['global'])
  const { viewer } = useStores()
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  if (!viewer.isSignedIn) {
    return (
      <Link href='/signin'>
        <a className={styles.signIn}>{t('ログイン')}</a>
      </Link>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.avatar} onClick={toggleDropDown} onKeyPress={toggleDropDown} role='button' tabIndex={0}>
        <Avatar2 size='md' src={getUserAvatar(viewer.viewer)} />
      </div>
      <div
        className={classNames(styles.menu, {
          [styles.activeMenu]: isOpen,
        })}
      >
        <GlobalMobileHeaderAccountMenuDropdown toggleDropDown={toggleDropDown} />
      </div>
      <OutSideOverlay onClick={toggleDropDown} isOpen={isOpen} />
    </div>
  )
})
