import { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

export const TopPageHeroTopJa241219: FC = observer(() => {
  const { t } = useTranslation(['index'])

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.bgImg} />
        <div className={styles.content}>
          <div className={styles.title}>
            <p className={styles.text1}>{t('最適な投資家との出会いを、')}</p>
            <p className={styles.text2}>{t('｢最速｣で｡')}</p>
          </div>
          <div className={styles.info}>
            <div className={styles.data}>
              <p className={styles.label}>{t('掲載投資会社総数')}</p>
              <p className={styles.value}>{t('10,302社')}</p>
            </div>
            <div className={styles.data}>
              <p className={styles.label}>{t('日本の投資会社数')}</p>
              <p className={styles.value}>{t('5,395社')}</p>
            </div>
            <div className={styles.data}>
              <p className={styles.label}>{t('掲載国数')}</p>
              <p className={styles.value}>{t('21カ国')}</p>
            </div>
          </div>
          <a
            className={styles.link}
            href={sanitizeUrl(`${process.env.NEXT_PUBLIC_CONTACT_URL}`)}
            target='_blank'
            rel='noreferrer'
          >
            <p className={styles.btn}>{t('担当者との面談を申し込む')}</p>
          </a>
          <div className={styles.img} />
        </div>
      </div>
    </section>
  )
})
