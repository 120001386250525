import { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import styles from './index.module.scss'

export const TopPageHeroFeatureList241219: FC = observer(() => {
  const { t } = useTranslation(['index'])
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <p className={styles.sub}>Features</p>
            <p className={styles.main}>{t('TOSHIKA DBの特徴')}</p>
          </div>
          <div className={styles.list}>
            <div className={styles.row}>
              <div className={styles.card}>
                <div className={styles.img}>
                  <div className={styles.icon1} />
                </div>
                <p className={styles.cardTitle}>{t('アジア中の投資会社情報を網羅')}</p>
                <p className={styles.cardText}>
                  {t(
                    'VC（ベンチャーキャピタル）、CVC（コーポレートベンチャーキャピタル）、上場企業、非上場企業の詳細なデータが利用可能。これにより、アジア市場全体の投資会社の動向を把握することができます。'
                  )}
                </p>
              </div>
              <div className={styles.card}>
                <div className={styles.img}>
                  <div className={styles.icon2} />
                </div>
                <p className={styles.cardTitle}>{t('投資先も含めた検索が可能')}</p>
                <p className={styles.cardText}>
                  {t(
                    '投資元と投資先の双方向の情報を把握できます。これにより、特定の企業がどこに投資しているか、また特定のスタートアップがどの企業から投資を受けているかなど、多角的な分析が可能となります。'
                  )}
                </p>
              </div>
              <div className={styles.card}>
                <div className={styles.img}>
                  <div className={styles.icon3} />
                </div>
                <p className={styles.cardTitle}>{t('最適な投資会社を発見可能')}</p>
                <p className={styles.cardText}>
                  {t(
                    '多様なフィルターを使用して、自社に最適な投資会社を発見できる。業種、投資規模、地域などの条件を組み合わせることで、ニーズに合った投資家を効率的に見つけ出すことが可能です。'
                  )}
                </p>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.card}>
                <div className={styles.img}>
                  <div className={styles.icon4} />
                </div>
                <p className={styles.cardTitle}>{t('投資家管理ツールも使用可能')}</p>
                <p className={styles.cardText}>
                  {t(
                    '発見した自社に最適な投資家をターゲットリストに追加し、コメントなどを記録することができます。これにより、投資家とのコンタクトを効果的に管理し、資金調達プロセスを効率化できます。'
                  )}
                </p>
              </div>
              <div className={styles.card}>
                <div className={styles.img}>
                  <div className={styles.icon5} />
                </div>
                <p className={styles.cardTitle}>{t('投資会社の口コミを記載・閲覧')}</p>
                <p className={styles.cardText}>
                  {t(
                    '投資会社に関する口コミを記載・閲覧可能です。この機能を通じて、起業家や企業の生の声を共有することで、投資会社の公式情報だけでは得られない貴重な情報を入手でき、最適な投資家選びに役立てることができます。'
                  )}
                </p>
              </div>
              <div className={styles.card}>
                <div className={styles.img}>
                  <div className={styles.icon6} />
                </div>
                <p className={styles.cardTitle}>{t('投資会社からの逆オファー制度')}</p>
                <p className={styles.cardText}>
                  {t(
                    '投資会社からの逆オファー制度あり（オプション）。この機能を利用することで、自社のプロジェクトや事業計画に興味を持つ投資家からの直接的なアプローチを受けることができ、資金調達の機会を拡大できます。'
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})
