import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  ICorporateInvestorListBase,
  ICorporateInvestorsService,
  IUpdateCorporateInvestorListUseCase,
  IUseCaseOutputFactory,
  UpdateCorporateInvestorListUseCaseInput,
  UpdateCorporateInvestorListUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdateCorporateInvestorListInteractor implements IUpdateCorporateInvestorListUseCase {
  @inject(symbols.ICorporateInvestorsService) private corporateInvestorService: ICorporateInvestorsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    corporateInvestorList: ICorporateInvestorListBase
  }>

  async handle(input: UpdateCorporateInvestorListUseCaseInput): Promise<UpdateCorporateInvestorListUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        corporateInvestorList: null,
      },
    })

    try {
      const response = await this.corporateInvestorService.updateCorporateInvestorList(input)
      output.data.corporateInvestorList = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
