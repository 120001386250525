import { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Language, MessageType } from '@/types'
import { getStaticStores, useStores } from '@/utils'
import { PageWithHeroTemplate } from '@components/templates/PageWithHeroTemplate'
import { TopPageHero241219 } from '@components/organisms/TopPageHero241219'
import { MyHead } from '@components/molecules/MyHead'
import { DbPaymentModal } from '@/components/organisms/DbPaymentModal'
import styles from './index.module.scss'

const Home: NextPage = () => {
  const { t } = useTranslation(['index'])
  const { messages } = useStores()
  const router = useRouter()

  useEffect(() => {
    // メールアドレス確認リンクを2回クリックした際のクエリパラメータをハンドリングする
    if (router.asPath === '/?error=invalid_confirmation_token') {
      messages.add({
        type: MessageType.Error,
        body: t('メールアドレス確認リンクが間違っています。この症状が続く場合はサポートへお問い合わせください。'),
        isTranslated: true,
        isDismissable: true,
      })
    } else if (router.asPath === '/?error=already_confirmed') {
      messages.add({
        type: MessageType.Info,
        body: t('このメールアドレスは確認済みです。'),
        isTranslated: true,
        isDismissable: true,
      })
    } else if (router.asPath === '/?left_service=true') {
      messages.add({
        type: MessageType.Info,
        body: t('退会しました。'),
        isTranslated: true,
        isDismissable: true,
      })
    }
  }, [])

  return (
    <PageWithHeroTemplate>
      <MyHead title={t('TOSHIKA')} isTitleWithSuffix={false} />
      <div className={styles.container}>
        <TopPageHero241219 />
      </div>
      <DbPaymentModal />
    </PageWithHeroTemplate>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { viewer } = getStaticStores()

  await viewer.changeLanguage(locale as Language)

  return {
    props: {
      forcedTheme: 'light',
      ...(await serverSideTranslations(locale, ['global', 'index', 'p'])),
    },
    revalidate: 30,
  }
}

export default Home
