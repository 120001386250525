import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IUseCaseOutputFactory,
  IFetchExternalLinksUseCase,
  ICorporateInvestorsService,
  IExternalLinkBase,
  FetchExternalLinksUseCaseInput,
  FetchExternalLinksUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class FetchExternalLinksInteractor implements IFetchExternalLinksUseCase {
  @inject(symbols.ICorporateInvestorsService) private corporateInvestorsService: ICorporateInvestorsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    externalLinks: IExternalLinkBase[]
    totalCount: number
    totalPages: number
    currentPage: number
    hasNextPage: boolean
  }>

  async handle(input: FetchExternalLinksUseCaseInput): Promise<FetchExternalLinksUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        externalLinks: [],
        totalCount: null,
        totalPages: null,
        currentPage: null,
        hasNextPage: false,
      },
    })

    try {
      const response = await this.corporateInvestorsService.fetchExternalLinks(input)
      const { externalLinks, totalCount, totalPages, currentPage, hasNextPage } = response

      output.data.externalLinks = externalLinks
      output.data.totalCount = totalCount
      output.data.totalPages = totalPages
      output.data.currentPage = currentPage
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
