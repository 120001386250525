import { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import styles from './index.module.scss'

export const TopPageHeroFAQ241219: FC = observer(() => {
  const { t } = useTranslation(['index'])

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <p className={styles.sub}>FAQ</p>
            <p className={styles.main}>{t('よくある質問')}</p>
          </div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.itemTitle}>
                <p className={styles.q}>
                  Q,
                  <br />
                </p>
                <p className={styles.question}>{t('投資会社データベースとは何ですか？')}</p>
              </div>
              <p className={styles.answer}>
                {t('投資会社が網羅的に収録されており、検索および閲覧が可能なサービスとなっております。')}
              </p>
            </div>
            <div className={styles.item}>
              <div className={styles.itemTitle}>
                <p className={styles.q}>
                  Q,
                  <br />
                </p>
                <p className={styles.question}>{t('逆オファー制度とは何ですか？')}</p>
              </div>
              <p className={styles.answer}>{t('貴社の情報を投資会社に提供し、マッチングを行うサービスとなります。')}</p>
            </div>
            <div className={styles.item}>
              <div className={styles.itemTitle}>
                <p className={styles.q}>
                  Q,
                  <br />
                </p>
                <p className={styles.question}>
                  {t('資金調達全般について包括的な支援をしていただくことは可能でしょうか？')}
                </p>
              </div>
              <p className={styles.answer}>{t('可能です。担当者との面談についてご相談ください。')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})
