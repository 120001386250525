import { FC } from 'react'
import { GlobalDesktopHeaderNavMenu, GlobalDeskTopNavMenuItem } from '@components/molecules/GlobalDesktopHeaderNavMenu'
import { useTranslation } from 'next-i18next'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import styles from './index.module.scss'

const MENU_KEY = {
  CORPORATE_INVESTORS: 'corporate_investors',
  LP: 'lp',
  AGENCY: 'agency',
  NONE: 'none',
}
Object.freeze(MENU_KEY)

type MenuItem = GlobalDeskTopNavMenuItem & {
  key: string
}

export const GlobalDesktopHeaderNavigation: FC = observer(() => {
  const { t } = useTranslation(['global'])
  const { viewer } = useStores()
  const rawMenuItems: MenuItem[] = [
    {
      key: MENU_KEY.CORPORATE_INVESTORS,
      name: t('投資会社を探す'),
      href:
        viewer.isSignedIn && viewer.viewer.hasValidSubscription
          ? `/${viewer.viewer?.username}/corporate-investors`
          : '/corporate-investors',
      subMenus: [],
    },
    {
      key: MENU_KEY.LP,
      name: t('資金調達戦略診断'),
      href: '/qa',
      subMenus: [],
    },
    {
      key: MENU_KEY.AGENCY,
      name: t('代理店募集'),
      href: process.env.NEXT_PUBLIC_AGENCY_URL,
      isExternal: true,
      subMenus: [],
    },
    // {
    //   key: MENU_KEY.ANGELS,
    //   name: t('エンジェル投資家を探す'),
    //   isMultipleColumns: router.locale === 'ja', // 日本語のみメニューが長くなりすぎるので複数カラム
    //   href: `/${viewer.viewer?.username}/angels`,
    //   subMenus: [],
    // },
  ]

  let menuItems: MenuItem[] = []

  menuItems = rawMenuItems

  return (
    <>
      <div className={styles.container}>
        {menuItems.map((item) => {
          return (
            <GlobalDesktopHeaderNavMenu
              key={item.name}
              name={item.name}
              href={item.href}
              subMenus={item.subMenus}
              isMultipleColumns={item.isMultipleColumns}
              isExternal={item.isExternal}
            />
          )
        })}
      </div>
    </>
  )
})
